<template>
    <v-dialog v-model="isOpen" width="700px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <ValidationObserver ref="observer" v-slot="{ invalid }">
                <v-toolbar dense flat>
                    <v-toolbar-title class="title primary--text">
                        <template v-if="initPartnerContactId">
                            Dénoncer à nouveau le contact
                        </template>
                        <template v-else>
                            Dénoncer un nouveau contact
                        </template>
                    </v-toolbar-title>

                    <v-spacer />

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                                <v-icon> far fa-times-circle </v-icon>
                            </v-btn>
                        </template>
                        Fermer la fenêtre
                    </v-tooltip>
                </v-toolbar>

                <v-divider />

                <v-card-text class="pt-4 px-4">
                    <template v-if="initPartnerContactId">
                        <ContactAutocomplete label="Contact" :value="visitorId" :initPartnerContactId="initPartnerContactId" disabled :multiple="false" class="mb-4" />
                    </template>

                    <template v-else>
                        <v-row class="mb-2">
                            <v-col cols="12" lg="4">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.title.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.title.rules')">
                                    <v-select v-model="contact.title" :items="titles" :label="getConfig('contacts.denonciation_mandatory_fields.title.name') + (getConfig('contacts.denonciation_mandatory_fields.title.required') ? ' *' : '')" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" lg="4">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.name.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.name.rules')">
                                    <v-text-field v-model="contact.name" :label="getConfig('contacts.denonciation_mandatory_fields.name.name') + (getConfig('contacts.denonciation_mandatory_fields.name.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" lg="4">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.firstname.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.firstname.rules')">
                                    <v-text-field v-model="contact.firstname" :label="getConfig('contacts.denonciation_mandatory_fields.firstname.name') + (getConfig('contacts.denonciation_mandatory_fields.firstname.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.email.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.email.rules')">
                            <v-text-field v-model="contact.email" class="mb-4" :label="getConfig('contacts.denonciation_mandatory_fields.email.name') + (getConfig('contacts.denonciation_mandatory_fields.email.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                        </ValidationProvider>

                        <v-row v-if="getConfig('contacts.denonciation_phone_or_mobile_required', false)" class="mb-2">
                            <v-col cols="12" lg="6">
                                <ValidationProvider v-slot="{ errors, failed }" vid="mobile" :name="getConfig('contacts.denonciation_mandatory_fields.mobile.name')" :rules="{ required: !contact.phone, 'phone': true }">
                                    <v-text-field v-model="contact.mobile" :label="getConfig('contacts.denonciation_mandatory_fields.mobile.name') + (!contact.phone || contact.phone && contact.mobile ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <ValidationProvider v-slot="{ errors, failed }" vid="phone" :name="getConfig('contacts.denonciation_mandatory_fields.phone.name')" :rules="{ required: !contact.mobile, 'phone': true }">
                                    <v-text-field v-model="contact.phone" :label="getConfig('contacts.denonciation_mandatory_fields.phone.name') + (!contact.mobile || contact.phone && contact.mobile ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-row v-if="!getConfig('contacts.denonciation_phone_or_mobile_required', false)" class="mb-2">
                            <v-col cols="12" lg="6">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.mobile.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.mobile.rules')">
                                    <v-text-field v-model="contact.mobile" :label="getConfig('contacts.denonciation_mandatory_fields.mobile.name') + (getConfig('contacts.denonciation_mandatory_fields.mobile.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.phone.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.phone.rules')">
                                    <v-text-field v-model="contact.phone" :label="getConfig('contacts.denonciation_mandatory_fields.phone.name') + (getConfig('contacts.denonciation_mandatory_fields.phone.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.address.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.address.rules')">
                            <v-text-field v-model="contact.address" class="mb-4" :label="getConfig('contacts.denonciation_mandatory_fields.address.name') + (getConfig('contacts.denonciation_mandatory_fields.address.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                        </ValidationProvider>

                        <v-row class="mb-2">
                            <v-col cols="12" lg="4">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.city.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.city.rules')">
                                    <v-text-field v-model="contact.city" :label="getConfig('contacts.denonciation_mandatory_fields.city.name') + (getConfig('contacts.denonciation_mandatory_fields.city.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" lg="4">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.zip.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.zip.rules')">
                                    <v-text-field v-model="contact.zip" :label="getConfig('contacts.denonciation_mandatory_fields.zip.name') + (getConfig('contacts.denonciation_mandatory_fields.zip.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" lg="4">
                                <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.country.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.country.rules')">
                                    <v-text-field v-model="contact.country" :label="getConfig('contacts.denonciation_mandatory_fields.country.name') + (getConfig('contacts.denonciation_mandatory_fields.country.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </template>

                    <v-divider class="mb-5" />

                    <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.program.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.program.rules')">
                        <ProgramAutocomplete ref="programAutocomplete" v-model="contact.programId" :label="getConfig('contacts.denonciation_mandatory_fields.program.name') + (getConfig('contacts.denonciation_mandatory_fields.program.required') ? ' *' : '')" noDynamicSearch :multiple="false" :disabled="!!initProgramId" :errors="errors" :failed="failed" class="mb-4" />
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.finalite.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.finalite.rules')">
                        <FinaliteSelect v-model="contact.finalite" :label="getConfig('contacts.denonciation_mandatory_fields.finalite.name') + (getConfig('contacts.denonciation_mandatory_fields.finalite.required') ? ' *' : '')" :errors="errors" :failed="failed" class="mb-4" />
                    </ValidationProvider>

                    <v-divider class="mb-5" />

                    <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.comment.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.comment.rules')">
                        <v-textarea v-model="contact.comments" :label="getConfig('contacts.denonciation_mandatory_fields.comment.name') + (getConfig('contacts.denonciation_mandatory_fields.comment.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" rows="2" outlined dense />
                    </ValidationProvider>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-btn :disabled="invalid" @click="submit()" color="primary" depressed small>
                        Créer
                    </v-btn>

                    <v-spacer />

                    <v-btn @click="isOpen = false" depressed small>
                        Annuler
                    </v-btn>
                </v-card-actions>
            </ValidationObserver>
        </v-card>

        <ContactPartnerSelectionDialog ref="contactPartnerSelection" @partner-selection-submit="partnerContactSelected" />
    </v-dialog>
</template>

<script>
import FinaliteSelect from '../widgets/FinaliteSelect.vue';
import ContactAutocomplete from '../widgets/ContactAutocomplete.vue';
import ProgramAutocomplete from '../widgets/ProgramAutocomplete.vue';
import ContactPartnerSelectionDialog from './ContactPartnerSelectionDialog.vue';

export default {
    name: 'CreateDenonciationDialog',

    components: {
        FinaliteSelect,
        ContactAutocomplete,
        ProgramAutocomplete,
        ContactPartnerSelectionDialog
    },

    props: {
        visitorId: { type: Number },
        initProgramId: { type: Number },
        initPartnerContactId: { type: Number },
        redirectOnCreation: { type: Boolean, default: false }
    },

    data: () => ({
        isOpen: false,
        titles: [],
        contact: {}
    }),

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = {
                    contact: this.contact
                };

                const { success, visitorId, partnerContactId, contacts, err } = await this.repos.contacts.createContact(body);

                if (success) {
                    this.$notify({
                        title: 'Information',
                        text: 'Votre demande est en attente de validation. Vous allez recevoir un mail de validation ou de refus de votre dénonciation. Merci de patienter avant toute pose d\'option.',
                        type: 'success',
                        duration: 15000
                    });
                    this.isOpen = false;
                    this.$emit('denonciation-created', { visitorId, partnerContactId });
                    if (this.redirectOnCreation) {
                        this.$router.push(`/contacts/${visitorId}`);
                    }
                } else {
                    if (contacts) {
                        this.$refs.contactPartnerSelection.open(contacts);
                    } else {
                        throw new Error(err);
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la dénonciation du contact',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async partnerContactSelected(partnerContactId) {
            try {
                this.setLoading(true);

                const body = {
                    contact: this.contact
                };
                body.contact.id = partnerContactId;

                if (!partnerContactId) {
                    body.contact.forceCreation = true;
                }

                const { success, visitorId, contacts } = await this.repos.contacts.createContact(body);
                if (success) {
                    this.$notify({
                        title: 'Information',
                        text: 'Votre demande est en attente de validation. Vous allez recevoir un mail de validation ou de refus de votre dénonciation. Merci de patienter avant toute pose d\'option.',
                        type: 'success',
                        duration: 15000
                    });
                    this.isOpen = false;
                    this.$emit('denonciation-created', { partnerContactId });
                    if (this.redirectOnCreation) {
                        this.$router.push(`/contacts/${visitorId}`);
                    }
                } else {
                    if (contacts) {
                        this.$refs.contactPartnerSelection.open(contacts);
                    } else {
                        throw new Error('aze');
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la dénonciation du contact',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen(isOpen) {
            if (isOpen) {
                this.$nextTick(() => {
                    if (!this.initProgramId) {
                        this.$refs.programAutocomplete.fetchPrograms({ limit: 1000, archived: 0 });
                    }
                });
                const codes = [this.getConfig('contacts.finalite_parameter')];
                this.$store.dispatch('parameters/fetchParameters', codes);
            }
        }
    },

    created() {
        if (this.initPartnerContactId) {
            this.contact.id = this.initPartnerContactId;
        }
        if (this.initProgramId) {
            this.contact.programId = this.initProgramId;
        }

        switch (this.$store.state.application.providerCode) {
            case 'lamotte':
                this.titles = ['Mme', 'Mr', 'Mr et Mme', 'MM', 'Mmes', 'SCI'];
                break;
            case 'letp':
                this.titles = ['Mme', 'Mr'];
                break;
            default:
                this.titles = ['Mlle', 'Mme', 'Mr', 'Mr et Mme', 'MM', 'Mmes', 'SCI'];
                break;
        }
    }
};
</script>
